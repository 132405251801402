import Vue from 'vue'
import Vuex from 'vuex'
import { login } from './store_login'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },
})

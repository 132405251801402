import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/dashboard/login'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // 지부관리
        {
          name: 'jibumanage',
          path: 'pages/jibumanage',
          component: () => import('@/views/dashboard/pages/jibumanage'),
          meta: { requiresAuth: 3 },
        },
        // 사용자관리
        {
          name: 'usermanage',
          path: 'pages/usermanage',
          component: () => import('@/views/dashboard/pages/usermanage'),
          meta: { requiresAuth: 3 },
        },
        // 사용자관리
        {
          name: 'cabmanage',
          path: 'pages/cabmanage',
          component: () => import('@/views/dashboard/pages/cabmanage'),
          meta: { requiresAuth: 3 },
        },
        {
          name: 'printlog',
          path: 'pages/printlog',
          component: () => import('@/views/dashboard/pages/printlog'),
        },
        {
          name: 'logout',
          path: 'dashboard/logout',
          component: () => import('@/views/dashboard/logout'),
        },
      ],
    },
  ],
})

// 네비게이션 가드((뷰 라우터로 URL 접근에 대해서 처리할 수 있음)
router.beforeEach(async (to, from, next) => { // 여기서 모든 라우팅이 대기 상태가 됨
  /**
   * to: 이동할 url 정보가 담긴 라우터 객체
   * from: 현재 url 정보가 담긴 라우터 객체
   * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
   * next() 가 호출되기 전까지 화면 전환되지 않음
   */
  if (to.name !== 'Login') {
    if (VueCookies.get('accessToken') === null && VueCookies.get('refreshToken') !== null) {
      // refreshToken은 있고 accessToken이 없을 경우 토큰 재발급 요청
      await store.dispatch('refreshToken')
    }
    if (VueCookies.get('accessToken') !== null) {
      // accessToken이 있을 경우 진행
      // return next()
    }
    // 둘다 없을 경우에는 여기서 요청을 너무 때려서 주석처리하고 App.uve에다가 created 훅에다가 추가함
    if (VueCookies.get('accessToken') === null && VueCookies.get('refreshToken') === null) {
       // 2개 토큰이 모두 없을 경우 로그인페이지로
       return next({ name: 'Login' })
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (VueCookies.get('permlevel_cd') >= 3) {
      return next()
    } else {
      return next({ name: '' })
    }
  } else {
      next() // make sure to always call next()!
  }
  return next()
})



export default router

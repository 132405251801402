import axios from 'axios'
import VueCookies from 'vue-cookies'
// 로그인 처리 관련 저장소 모듈
export const login = {
    state: {
        host: 'https://gnpta.com:3000',
        accessToken: null,
        refreshToken: null,
        user_id: null,
        user_cd: null,
        user_nm: null,
        jibu_cd: null,
        permlevel_cd: null,
    },
    mutations: {
        loginToken (state, payload) {
            VueCookies.set('accessToken', payload.accessToken, '60s')
            VueCookies.set('refreshToken', payload.refreshToken, '1h')
            state.accessToken = payload.accessToken
            state.refreshToken = payload.refreshToken
        },
        refreshToken (state, payload) { // accessToken 재셋팅
          VueCookies.set('accessToken', payload.accessToken, '60s')
          VueCookies.set('refreshToken', payload.refreshToken, '1h')
          state.accessToken = payload
        },
        removeToken () {
          VueCookies.remove('accessToken')
          VueCookies.remove('refreshToken')
        },
        setInfo (state, payload) {
          state.user_cd = payload.user_cd
          state.user_nm = payload.user_nm
          state.jibu_cd = payload.jibu_cd
          state.permlevel_cd = payload.permlevel_cd
          VueCookies.set('user_cd', payload.user_cd)
          VueCookies.set('user_nm', payload.user_nm)
          VueCookies.set('jibu_cd', payload.jibu_cd)
          VueCookies.set('permlevel_cd', payload.permlevel_cd)
      },
    },
    getters: {
      // 쿠키에 저장된 토큰 가져오기
      getToken (state) {
        const ac = VueCookies.get('accessToken')
        const rf = VueCookies.get('refreshToken')
        return {
          access: ac,
          refresh: rf,
        }
      },
      getInfo (state) {
        return {
          user_cd: state.user_cd,
          user_nm: state.user_nm,
          jibu_cd: state.jibu_cd,
          permlevel_cd: state.permlevel_cd,
        }
      },
    },
    actions: {
      login: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
          axios.post('https://gnpta.com:3000/login', params).then(res => {
            if (res.data.otpkey) {
              console.log('optkey', res.data.otpkey)
              resolve(res)
            } else {
              commit('loginToken', res.data.auth_info)
              commit('setInfo', res.data.set_info)
              console.log('setInfo', res.data.set_info)
              resolve(res)
            }
          })
          .catch(err => {
            console.log(err.message)
            reject(err.message)
          })
        })
      },
      login2: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
          commit('loginToken', params.data.auth_info)
          commit('setInfo', params.data.set_info)
          console.log('setInfo', params.data.set_info)
          resolve(params)
        })
      },
      refreshToken: ({ commit }) => { // accessToken 재요청
        // accessToken 만료로 재발급 후 재요청시 비동기처리로는 제대로 처리가 안되서 promise로 처리함
        return new Promise((resolve, reject) => {
          axios.post('https://gnpta.com:3000/certify', null, {
            headers: {
              'x-refresh-token': VueCookies.get('refreshToken'),
              },
            },
          ).then(res => {
            commit('refreshToken', res.data.auth_info)
            resolve(res.data.auth_info)
          }).catch(err => {
            console.log('refreshToken error : ', err.config)
            commit('removeToken')
            location.reload()
            reject(err.config.data)
          })
        })
      },
      logout: ({ commit }) => { // 로그아웃
        commit('removeToken')
        location.reload()
      },
    },
}
